/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {StateCta, ThreeColCta} = _components;
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the dynamic world of entrepreneurship, success stories often begin with a simple spark of inspiration and a deep friendship. Dr. Marco Vietor and Paul Crusius, both alumni of WHU-Otto Beisheim School of Management, exemplify this narrative of innovation and partnership. Their journey together led to the creation of ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", now recognized as the fastest-growing hearing care company globally."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The roots of their remarkable venture trace back to their enduring friendship that was cultivated during their university days. Despite the demands of their respective careers, Marco and Paul remained steadfast friends, meeting every six months for dinner and cherishing the bond they had formed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In 2011, fate intervened in the form of an unconventional Skype call. Marco's father chose the video communication platform over traditional phone calls, inadvertently sparking a conversation that would alter the trajectory of their lives. This seemingly ordinary event led to a profound realization: the internet was no longer the exclusive domain of young people. There existed an untapped potential among the older generation, often referred to as baby boomers or silver surfers, who were increasingly navigating the online landscape."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Recognizing the vast opportunities awaiting in this underserved demographic, Marco and Paul set their sights on a unique mission. They envisioned a dedicated service that catered specifically to the needs of silver surfers. The question was, where could they make the most impact? The answer came in the form of hearing loss, a prevalent issue that affected many in the aging population."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With a shared vision and a commitment to providing a state-of-the-art solution for hearing loss, Marco and Paul embarked on an entrepreneurial journey that would change the lives of countless individuals. They founded hear.com group, a trailblazing company that would redefine the hearing care industry."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Headquartered in both Miami, Florida, and Berlin, Germany, hear.com quickly became a global phenomenon. The company operates under two core brands, hear.com in North America and Asia, and audibene in Europe. Today, hear.com group is active in ten countries and employs over 1,200 dedicated individuals who share Marco and Paul's vision of revolutionizing the way hearing care is delivered."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The story of Dr. Marco Vietor and Paul Crusius serves as a testament to the power of friendship, innovation, and recognizing unmet needs in society. Through hear.com, they have not only built a thriving business but have also significantly improved the quality of life for countless individuals experiencing hearing loss. Their journey is a shining example of how an idea, sparked by a Skype call, can grow into a global success story that positively impacts lives around the world."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "There are several types of hearing loss and many different cause.",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
